import React, { useEffect, useState } from 'react';

import DataTable from './dataTable';
import SetQuestions from './SetQuestions/setQuestion';
import LlmAnswer from './LLMAnswers/llMAnswer';
import FreeUsers from './freeUsers/freeUsers';
import { EntityService, QuestionsService } from '../../../services';
import ChiefComplaint from './Models/ChiefComplaint';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HealingIcon from '@mui/icons-material/Healing';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { BottomNavigation, BottomNavigationAction, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import SkippedQuestions from './SkippedQuestion/SkippedQuestions';
import UndefindQuestions from './UndefindQuestion/UndefindQuestions';
import CustomLoader from '../../../common/customLoader';

export default function CrudIndex() {
  const [allQuestionsData, setAllQuestionsData] = useState([]);
  const [allLots, setAllLots] = useState([]);
  const [allDisorderData, setAllDisorderData] = useState([]);
  const [activeTab, setActiveTab] = useState('Questions'); // Initial active tab
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('BASELINE');

  useEffect(() => {
    getEntitiesList();
  }, []);

  const getEntitiesList = async () => {
    try {
      const response = await EntityService.getEntityData();
      if (response && response.length) {
        setEntities([...response, { collectionType: 'BASELINE', name: 'BASELINE' }]);
      } else {
        setEntities([{ collectionType: 'BASELINE', name: 'BASELINE' }]);
      }
    } catch (error) {
      console.error('Error fetching corporate data:', error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response;
      switch (activeTab) {
        case 'Questions':
          const allQuestionsDataRes = await QuestionsService.getAllQuestionsData({ collectionType: selectedCollection });
          response = allQuestionsDataRes; // Assign the correct response variable
          break;
        case 'LOTS':
          const allLotsRes = await QuestionsService.getAllLots({ collectionType: selectedCollection });
          response = allLotsRes; // Assign the correct response variable
          break;
        case 'Disorder':
          const allDisorderRes = await QuestionsService.getAllDisorderData({ collectionType: selectedCollection });
          response = allDisorderRes; // Assign the correct response variable
          break;
        default:
          response = null;
          setIsLoading(false);
      }

      if (response) {
        const data = await response;
        switch (activeTab) {
          case 'Questions':
            setAllQuestionsData(data);
            break;
          case 'LOTS':
            setAllLots(data); // Check if this function name is correct
            break;
          case 'Disorder':
            let newData = data.sort((a, b) => a.lotId - b.lotId);
            setAllDisorderData(newData);
            break;
          default:
            break;
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  let content;

  switch (activeTab) {
    case 'SetQuestions':
      content = <SetQuestions />;
      break;
    case 'FreeUsers':
      content = <FreeUsers />;
      break;
    case 'Chief Complaint':
      content = <ChiefComplaint />;
      break;
    case 'LlmAnswer':
      content = <LlmAnswer />;
      break;
    case 'Skippedquestions':
      content = <SkippedQuestions />;
      break;
    case 'UndefindQuestions':
      content = <UndefindQuestions />;
      break;
    default:
      content = (
        <>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <CustomLoader />
            </div>
          ) : (
            <DataTable activeTab={activeTab} allQuestionsData={allQuestionsData} allLots={allLots} allDisorderData={allDisorderData} />
          )}
        </>
      );
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, selectedCollection]);

  return (
    <>
      <BottomNavigation
        sx={{ mb: 3, borderBottom: '1px solid grey', flex: '0 0 auto' }}
        showLabels
        value={activeTab}
        onChange={(event, newValue) => {
          setActiveTab(newValue);
        }}
      >
        <BottomNavigationAction value="Disorder" label="Disorders" icon={<HealingIcon />} />
        <BottomNavigationAction value="Questions" label="Questions" icon={<QuestionMarkIcon />} />
        <BottomNavigationAction value="LOTS" label="LOTS" icon={<ListAltIcon />} />
        {/* <BottomNavigationAction
          value="SetQuestions"
          label="Set Questions"
          icon={<ListAltIcon />}
        /> */}
        <BottomNavigationAction value="Skippedquestions" label="Skipped Questions" icon={<PublishedWithChangesIcon />} />
        <BottomNavigationAction value="UndefindQuestions" label="Unspecified" icon={<ErrorOutlineIcon />} />
        {/* <BottomNavigationAction value="LlmAnswer" label="LLM Answers" icon={<ListAltIcon />} /> */}
        {/* <BottomNavigationAction value="FreeUsers" label="Free User" icon={<MoneyOffIcon />} /> */}
        {/* <BottomNavigationAction value="Chief Complaint" label="Chief Complaint" icon={<DriveFileRenameOutlineIcon />} /> */}
      </BottomNavigation>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 10 }}>Select The Collection Type</div>
        <FormControl fullWidth style={{ width: '200px' }}>
          <InputLabel>Type</InputLabel>
          <Select
            name="type"
            label="Type"
            value={selectedCollection}
            onChange={e => {
              setSelectedCollection(e.target.value);
            }}
          >
            {entities.map(entity => (
              <MenuItem value={entity.collectionType}>{entity.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ overflowY: 'auto', flex: 1 }}>{content}</div>
    </>
  );
}
